// /*    Print Style*/
/* @media print, (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 1.25dppx), (min-resolution: 120dpi) */

@media print {
  * {
    background: white !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;

    &::before, &::after {
      background: white !important;
      color: black !important;
      box-shadow: none !important;
      text-shadow: none !important; } }

  a {
    text-decoration: underline;

    &:visited {
      text-decoration: underline; }

    &[href]::after {
      content: "(" attr(href) ")"; } }

  abbr[title]::after {
    content: "(" attr(title) ")"; }

  a {
    &[href^="#"]::after, &[href^="javascript:"]::after {
      content: ""; } }

  pre {
    white-space: pre-wrap !important;
    border: 1px solid #999;
    page-break-inside: avoid; }

  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }

  thead {
    display: table-header-group; }

  tr, img {
    page-break-inside: avoid; }

  p, h2, h3 {
    orphans: 3;
    widows: 3; }

  h2, h3 {
    page-break-after: avoid; } }

/*    Ende Print Style    */
